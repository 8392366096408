import { CUSTOM_SETTING_WIKI_URL } from '@/components/CustomSettings/constants'
import { DashboardLayout } from '@/layouts/DashboardLayout/DashboardLayout'
import { useGetCustomSettingByName } from '@/services/hooks/custom-settings/useGetCustomSettingByName'
import { Typography } from '@mui/material'

const WikiPage = () => {
  const { data: wikiInfo } = useGetCustomSettingByName({
    name: CUSTOM_SETTING_WIKI_URL,
  })

  return (
    <DashboardLayout>
      {wikiInfo?.value?.content && (
        <iframe
          src={wikiInfo?.value?.content}
          height={1000}
          style={{ background: 'white' }}
        />
      )}
      {!wikiInfo?.value?.content && (
        <Typography>Отсутствует ссылка для контента</Typography>
      )}
    </DashboardLayout>
  )
}

export default WikiPage
