import { createBrowserRouter } from 'react-router-dom'

import { UserRole } from '@/constants'
import { AdminUsersTotalPage } from '@/pages/AdminUsersTotalPage'
import { CabinetPage } from '@/pages/CabinetPage'
import { CustomSettingsPage } from '@/pages/CustomSettingsPage'
import { ErrorPage } from '@/pages/ErrorPage'
import { FundsTransfersPage } from '@/pages/FundsTransfersPage'
import { GoogleSheetsPage } from '@/pages/GoogleSheetsPage'
import { LoginPage } from '@/pages/LoginPage'
import { MainPage } from '@/pages/MainPage'
import { NotFoundPage } from '@/pages/NotFoundPage'
import { ReviewsPage } from '@/pages/ReviewsPage'
import { RoomsPage } from '@/pages/RoomsPage'
import { TournamentsPage } from '@/pages/TournamentsPage'
import { UserBalancesPage } from '@/pages/UserBalancesPage'
import { UserContractsPage } from '@/pages/UserContractsPage'
import { UserGroupsPage } from '@/pages/UserGroupsPage'
import { UserReportsPage } from '@/pages/UserReportsPage'
import { UsersPage } from '@/pages/UsersPage'
import UsersRoomsSettingsPage from '@/pages/UsersRoomsSettingsPage.tsx'
import WikiPage from '@/pages/WikiPage'
import { PrivateRoute } from '@/router/PrivateRoute'

import { Helmet } from './Helmet'

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Helmet title="Страница не найдена | MaxEV portal">
        <NotFoundPage />
      </Helmet>
    ),
    errorElement: (
      <Helmet title="Неизвестная ошибка | MaxEV portal">
        <ErrorPage />
      </Helmet>
    ),
  },
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/login',
    element: (
      <Helmet title="Логин | MaxEV portal">
        <LoginPage />
      </Helmet>
    ),
  },
  {
    path: '/cabinet',
    element: (
      <PrivateRoute roles="all">
        <Helmet title="Личный кабинет | MaxEV portal">
          <CabinetPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/tournaments',
    element: (
      <PrivateRoute roles="all">
        <Helmet title="Турниры | MaxEV portal">
          <TournamentsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/rooms-balances',
    element: (
      <PrivateRoute roles="all">
        <Helmet title="Балансы на румах | MaxEV portal">
          <UserBalancesPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/funds-transfers',
    element: (
      <PrivateRoute roles="all">
        <Helmet title="Переводы | MaxEV portal">
          <FundsTransfersPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/users',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Пользователи | MaxEV portal">
          <UsersPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/user-groups',
    element: (
      <PrivateRoute roles={[UserRole.admin, UserRole.coach]}>
        <Helmet title="Группы пользователей | MaxEV portal">
          <UserGroupsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/user-reports/:id',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Отчёты игрока | MaxEV portal">
          <UserReportsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/reviews/user/:id',
    element: (
      <PrivateRoute roles={[UserRole.admin, UserRole.coach]}>
        <Helmet title="Отзывы об игроке | MaxEV portal">
          <ReviewsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/rooms',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Румы | MaxEV portal">
          <RoomsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/google-sheets',
    element: (
      <PrivateRoute roles={[UserRole.admin, UserRole.selector]}>
        <Helmet title="Google API | MaxEV portal">
          <GoogleSheetsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/users-total',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Таблица, средства | MaxEV portal">
          <AdminUsersTotalPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/user-contracts',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Пакеты | MaxEV portal">
          <UserContractsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/custom-settings',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Кастомные настройки | MaxEV portal">
          <CustomSettingsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/users/rooms',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Пользователи и румы | MaxEV portal">
          <UsersRoomsSettingsPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
  {
    path: '/cabinet/wiki',
    element: (
      <PrivateRoute roles={[UserRole.admin]}>
        <Helmet title="Wiki | MaxEV portal">
          <WikiPage />
        </Helmet>
      </PrivateRoute>
    ),
  },
])
